import {
  maxLengthError,
  maxLengthValidator,
  Message,
  requiredError,
  TextField
} from 'legos/text-field/base-field';
import { createElement, FC, ReactNode, useMemo } from 'react';

export interface UnknownFieldProps {
  /** The name of this form field, unique in the current form context */
  name: string;
  /** Label node to display to the user for this field */
  label: ReactNode;
  /**
   * Should the form field be required for the form or not
   * @default true
   */
  required?: boolean;
  /** Should the form field be uneditable and display the lock icon */
  readOnly?: boolean;
  /** The maximum characters allowed in the field at a time */
  maxLength?: number;
  /** Error to show when the field it required but is not completed */
  errorRequiredMessage?: ReactNode;
  /** Error to show when the field does not meet the max-length requirement */
  errorMaxLengthMessage?: ReactNode;
  /** A hint to show while the user is interacting with the field and has not had any errors */
  hintMessage?: ReactNode;
  /** A hash of error messages to show the user that occurred after a failed form submission */
  submitErrorMessages?: { [submitErrorName: string]: ReactNode };
}

/**
 * Use of UnknownField is highly discouraged and it is advised to look at all the available input
 * fields first before jumping on to using UnknownField. If you are using UnknownField field for a
 * specific requirements and you think requirements are not common then there could be a need of a
 * special lego input field.
 */

export const UnknownField: FC<UnknownFieldProps> = ({
  name,
  label,
  required,
  readOnly,
  maxLength,
  errorMaxLengthMessage,
  errorRequiredMessage,
  submitErrorMessages,
  hintMessage
}) => {
  const allowChange = useMemo(
    () => (maxLength ? maxLengthValidator(maxLength) : undefined),
    [maxLength]
  );

  return (
    <TextField
      allowChange={allowChange}
      name={name}
      label={label}
      required={required}
      readOnly={readOnly}
    >
      <Message changeError={maxLengthError} children={errorMaxLengthMessage} />
      <Message touched error={requiredError} children={errorRequiredMessage} />
      {submitErrorMessages &&
        Object.entries(submitErrorMessages).map(([error, node]) => (
          <Message
            key={error}
            submitError={error}
            dirtySinceLastSubmit={false}
            children={node}
          />
        ))}
      {hintMessage && <Message active>{hintMessage}</Message>}
    </TextField>
  );
};
